import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { NEWS_FEATURE } from './store/custom-news.state';
import { metaReducers, reducerNewsToken, reducerNewsTokenProvider } from './store/reducers/custom-news.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomNewsAdapter } from './connectors/custom-news.adapter';
import { CustomOccNewsAdapter } from './connectors/custom-occ-news.adapter';
import { CustomNewsListModule } from './components/custom-news-list/custom-news-list.module';
import { CustomNewsTitleModule } from './components/custom-news-title/custom-news-title.module';
import { CustomNewsSliderModule } from './components/custom-news-slider/custom-news-slider.module';
import { CustomNewsListEffects } from './store/effects/custom-news-list.effects';
import { CustomNewsDetailsEffects } from './store/effects/custom-news-details.effects';
import { CustomNewsSliderEffects } from './store/effects/custom-news-slider.effects';
import { CustomNewsCategoriesEffects } from './store/effects/custom-news-categories.effects';

@NgModule({
  imports: [
    CustomNewsListModule,
    CustomNewsTitleModule,
    CustomNewsSliderModule,
    StoreModule.forFeature(NEWS_FEATURE, reducerNewsToken, { metaReducers }),
    EffectsModule.forFeature([
      CustomNewsListEffects,
      CustomNewsDetailsEffects,
      CustomNewsSliderEffects,
      CustomNewsCategoriesEffects,
    ]),
  ],
  providers: [
    reducerNewsTokenProvider,
    {
      provide: CustomNewsAdapter,
      useClass: CustomOccNewsAdapter,
    },
  ],
})
export class CustomNewsModule {}
