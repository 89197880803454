import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomCheckoutDeliveryService} from "../../../../../core/checkout/facade/custom-checkout-delivery.service";
import {
  AddressValidation, Country, ErrorModel, GlobalMessageService, GlobalMessageType, Region, RoutingConfig, RoutingService, TranslationService,
  UserService
} from '@spartacus/core';
import { AddressFormComponent, ICON_TYPE, LaunchDialogService, sortTitles } from '@spartacus/storefront';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CustomUserAddressService } from 'src/app/spartacus/custom/core/user/facade/custom-user-address.service';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { CheckoutQueryService } from '@spartacus/checkout/base/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-custom-address-form',
  templateUrl: './custom-address-form.component.html',
  styleUrls: ['./custom-address-form.component.scss'],
})
export class CustomAddressFormComponent extends AddressFormComponent implements OnInit {

  @Input() showDetAsDefaultOption: boolean;
  @Input() loadAllCountries = false;
  iconTypes = ICON_TYPE;
  deliveryNoteObservationsLength = 120;
  deliveryNoteObservationsERPLength = 60;
  regionsSub: Subscription;

  addressForm: FormGroup = this.fb.group({
    country: this.fb.group({
      isocode: [null, Validators.required],
    }),
    titleCode: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    companyName: [''],
    line1: ['', [Validators.required, Validators.maxLength(35)]],
    line2: ['', Validators.maxLength(35)],
    town: ['', [Validators.required, Validators.maxLength(25)]],
    postalCode: ['', [
      Validators.required,
      Validators.maxLength(12),
      CustomValidators.postalCode('country.isocode'),
    ]],
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    phone: ['', [Validators.required, CustomValidators.phoneValidator]],
    email: ['', [
      Validators.required,
      Validators.maxLength(60),
      CustomValidators.emailValidator,
    ]],
    defaultAddress: [false],
    deliveryNoteObservations: ['', Validators.maxLength(120)],
  });
  addressVerifySub: any;

  constructor(
    protected fb: FormBuilder,
    protected checkoutDeliveryService: CustomCheckoutDeliveryService,
    protected userService: UserService,
    protected userAddressService: CustomUserAddressService,
    protected globalMessageService: GlobalMessageService,
    protected modalService: LaunchDialogService,
    protected translation: TranslationService,
    protected launchDialogService: LaunchDialogService,
    protected checkoutQueryService: CheckoutQueryService,
    protected routingService : RoutingService
  ) {
    super(fb, userService, userAddressService, globalMessageService, translation, launchDialogService);
  }

  ngOnInit(): void {
    // Fetching countries
    this.userAddressService.clearUserMiscData();
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.loadAllCountries ? this.userAddressService.loadDeliveryCountriesAll() : this.userAddressService.loadDeliveryCountries();
        }
      }),
    );

    // Fetching titles
    this.titles$ = this.getTitles().pipe(
      map((titles) => {
        titles.sort(sortTitles);
        const noneTitle = { code: '', name: 'Title' };
        return [noneTitle, ...titles];
      }),
    );

    // Fetching regions
    this.regions$ = this.selectedCountry$.pipe(
      switchMap((country) => this.userAddressService.getRegions(country)),
      tap((regions: Region[]) => {
        const regionControl = this.addressForm.get('region.isocode');
        if (regions && regions.length > 0) {
          regionControl.enable();
        } else {
          regionControl.disable();
        }
      }),
    );

    if (this.addressData && Object.keys(this.addressData).length !== 0) {
      if (this.addressData.defaultAddress) {
        this.deliveryNoteObservationsLength = this.deliveryNoteObservationsERPLength;
        this.addressForm.get('deliveryNoteObservations').setValidators([Validators.maxLength(this.deliveryNoteObservationsERPLength)]);
        this.addressForm.get('deliveryNoteObservations').updateValueAndValidity();
      }
      this.addressForm.patchValue(this.addressData);

      this.countrySelected(this.addressData.country);
      if (this.addressData.region) {
        this.regionSelected(this.addressData.region);
      }
    }

    this.addresses$ = this.userAddressService.getAddresses();
  }

  countrySelected(country: Country): void {
    super.countrySelected(country);
    this.addressForm['controls'].postalCode?.updateValueAndValidity();

    // Fetching regions
    this.regions$ = this.userAddressService.getRegions(country.isocode).pipe(
      tap((regions: Region[]) => {
        const regionControl = this.addressForm.get('region.isocode');
        if (regions && regions.length > 0) {
          regionControl.enable();
        } else {
          regionControl.disable();
        }
      }),
    );
  }
  handleVerifyAddress(): void {
    if (this.addressForm.valid) {
      const regionControl = this.addressForm.get('region');
      const isocode = regionControl?.value?.isocode;

      if (isocode) {
        this.regions$.pipe(take(1)).subscribe((regions: Region[]) => {
          if (regions.length) {
            const selectedRegion = regions.find(
              (region: Region) => region.isocode === isocode
            );
            regionControl?.patchValue({
              isocodeShort: selectedRegion?.isocodeShort,
            });
          } else {
            regionControl?.reset();
          }
        });
      }

      if (this.addressForm.dirty) {
        this.subscription.add(
          this.userAddressService
            .verifyAddress(this.addressForm.value)
            .subscribe((value) => {
              this.handleAddressVerificationResults(value);
            })
        );
      } else {
        // address form value not changed
        // ignore duplicate address
        this.submitAddress.emit(undefined);
      }
    } else {
      this.addressForm.markAllAsTouched();
    }
  }


  protected handleAddressVerificationResults(results: AddressValidation) {
    if (results.decision === 'ACCEPT') {
      this.submitAddress.emit(this.addressForm.value);
      this.routingService.go({ cxRoute: 'checkoutDeliveryAddress' });
    } else if (results.decision === 'REJECT') {
      if (
        results.errors?.errors.some(
          (error: ErrorModel) => error.subject === 'titleCode'
        )
      ) {
        this.globalMessageService.add(
          { key: 'addressForm.titleRequired' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      } else {
        var message = results.errors.errors[0].message;
        if(results.errors.errors[0].subject && this.addressForm.value[results.errors.errors[0].subject]){
          message = message + "- " + this.addressForm.value[results.errors.errors[0].subject];
        }
        this.globalMessageService.add(
          { key: 'addressForm.invalidAddress', params: { message } },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      }
    } else if (results.decision === 'REVIEW') {
      this.openSuggestedAddress(results);
    }
  }
}
