import { Observable } from 'rxjs';
import {
  CustomNewsCategoriesModel,
  CustomNewsDetailsModel,
  CustomNewsListModel,
  NewsFilter,
} from '../store/custom-news.model';
import { CustomNewsAdapter } from './custom-news.adapter';
import { CustomOcc } from '../../../../../spartacus/custom/core/occ/occ-models/custom-occ.models';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomOccNewsAdapter implements CustomNewsAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  public loadNewsList(
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    newsFilter?: NewsFilter,
  ): Observable<CustomNewsListModel> {
    const params = {};
    if (pageSize) {
      params['pageSize'] = pageSize.toString();
    }
    if (currentPage) {
      params['currentPage'] = currentPage.toString();
    }
    if (sort) {
      params['sort'] = sort;
    }
    for (const key in newsFilter) {
      if (Object.prototype.hasOwnProperty.call(newsFilter, key)) {
        const element = newsFilter[key];
        if (element) {
          params[key] = element;
        }
      }
    }

    const url = this.occEndpoints.buildUrl('news', null, params);

    return this.http.get<CustomOcc.NewsList>(url);
  }

  public loadNewsDetails(
    newsId: string,
  ): Observable<CustomNewsDetailsModel> {
    const url = this.occEndpoints.buildUrl('newsDetails', {
      urlParams: { newsId }
    });
    return this.http.get<CustomOcc.NewsDetails>(url);
  }

  public loadNewsCategories(): Observable<CustomNewsCategoriesModel> {
    const url = this.occEndpoints.buildUrl('newsCategories');
    return this.http.get<CustomNewsCategoriesModel>(url);
  }

}

