import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmarsysContactInfo } from '../../../../../model/preference-center.model';
import { CustomPreferenceCenterFacade } from '../../root/facade/custom-preference-center.facade';
import { CustomPreferenceCenterConnector } from '../connectors/custom-preference-center.connector';
import { CustomValidators } from '../../../../../spartacus/custom/shared/utils/validators/custom-validators';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPreferenceCenterService implements CustomPreferenceCenterFacade {

  protected busy$ = new BehaviorSubject(false);

  isUpdating$ = this.busy$.pipe(
    tap((state) => (state === true ? this.form.disable() : this.form.enable()))
  );

  constructor(
    protected fb: FormBuilder,
    protected preferenceCenterConnector: CustomPreferenceCenterConnector,
    protected globalMessageService: GlobalMessageService,
    protected routing: RoutingService,
  ) {}

  loadPreferenceCenterDetails(uid: string, storeId: string): Observable<EmarsysContactInfo> {
    return this.preferenceCenterConnector.loadPreferenceCenterDetails(uid, storeId);
  }

  updatePreferenceCenterDetails(
    uid: string, 
    cid: string, 
    llid: string,
    details: EmarsysContactInfo,
  ): Observable<EmarsysContactInfo> {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    this.preferenceCenterConnector.updatePreferenceCenterDetails(uid, cid, llid, details).subscribe({
      complete: () => this.onSuccess(),
      error: () => this.onError(),
    });
  }

  form: FormGroup = this.fb.group({
    name: ['', [Validators.maxLength(50)]],
    surnames: ['', [Validators.maxLength(50)]],
    email: ['', [Validators.maxLength(50), CustomFormValidators.emailValidator]],
    birthDate: ['', [CustomValidators.dateValidator]],
    province: ['', [Validators.maxLength(50)]],
    language: [''],
    phoneNumber: ['',[CustomValidators.phoneValidator, Validators.pattern('^[0-9]*$')]],
    subscribed: [''],
  });

  protected onSuccess(): void {
    this.globalMessageService.add(
      {
        key: 'preferenceCenter.form.preferenceUpdateSuccess',
      },
      GlobalMessageType.MSG_TYPE_CONFIRMATION,
    );

    this.busy$.next(false);
  }

  protected onError(): void {
    this.busy$.next(false);

    this.globalMessageService.add(
      {
        key: 'preferenceCenter.form.preferenceUpdateError',
      },
      GlobalMessageType.MSG_TYPE_ERROR,
    );
  }
}
