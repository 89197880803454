import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StateWithNews } from '../../store/custom-news.state';
import { CustomNewsListModel, CustomNewsModel, NewsFilter } from '../../store/custom-news.model';
import { Observable } from 'rxjs';
import { getNewsSliderLoading, getNewsSlides } from '../../store/selectors/custom-news-slider.selector';
import { LoadNewsSlider } from '../../store/actions/custom-news-slider.actions';
import { CustomNewsConnector } from '../../connectors/custom-news.connector';

@Injectable({
  providedIn: 'root',
})
export class CustomNewsSliderService {

  constructor(
    protected store: Store<StateWithNews>,
    protected newsConnector?: CustomNewsConnector,
  ) {
  }

  getNewsSlides(): Observable<CustomNewsModel[]> {
    return this.store.pipe(select(getNewsSlides));
  }

  getNewsSliderLoading(): Observable<boolean> {
    return this.store.pipe(select(getNewsSliderLoading));
  }

  fetchNewsSlider(
    pageSize?: number,
    currentPage = 0,
    sort?: string,
    newsFilter?: NewsFilter,
  ): void {
    this.store.dispatch(new LoadNewsSlider({ pageSize, currentPage, sort, newsFilter }));
  }

  loadFilteredNews(
    pageSize?: number,
    currentPage = 0,
    sort?: string,
    newsFilter?: NewsFilter,
  ): Observable<CustomNewsListModel> {
    return this.newsConnector.getNewsList(pageSize, currentPage, sort, newsFilter);
  }
}
