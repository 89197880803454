<ng-container *ngIf="product$ | async as product">
    <ul *ngIf="product?.classifications || product?.specifications">
        <ng-container *ngFor="let class of product?.classifications">
            <li *ngFor="let feature of class.features">
                <span class="icon">
                    <cx-media [container]="feature?.icon"></cx-media>
                </span>
                <div class="info">
                    <h3 class="title">
                        {{ feature?.name }}: 
                    </h3>
                    <ng-container *ngFor="let featureValue of feature?.featureValues">
                        <span>
                            {{ featureValue?.value }}
                            <ng-container
                                *ngIf="
                                feature.featureUnit?.symbol?.length > 0 &&
                                feature.featureUnit.unitType != '300'
                                "
                            >
                                {{ feature?.featureUnit?.symbol }}
                            </ng-container>
                        </span>
                    </ng-container>
                </div>
            </li>
        </ng-container>
        <li *ngFor="let specification of product?.specifications"> 
            <span [class]="specification?.mime?.includes('pdf') ? 'icon icon-icon-pdf' : 'icon icon-file-empty'">
            </span>
            <div class="info">
                <a [href]="getDownloadUrl(specification?.downloadUrl)"
                    class="btn-link title"
                    target="_blank"
                >
                    {{ specification?.name ? specification?.name : specification?.code}}
                </a>
            </div>
        </li>
    </ul>        
</ng-container>
