import { Component } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { LoginFormComponent } from '@spartacus/user/account/components';
import { CustomPopupShippingService } from 'src/app/custom/cms-components/user/popup-shipping/custom-popup-shipping.service';
import { CustomExtraAppConfigService } from 'src/app/custom/config/services/custom-extra-app-config.service';
import { CustomRoutingConfigService } from '../../../core/routing/custom-routing-config.service';
import { CsutomLoginFormComponentService } from '../../../core/user/facade/custom-login-form-component.service';

@Component({
  selector: 'app-custom-login-form',
  templateUrl: './custom-login-form.component.html',
  styleUrls: ['./custom-login-form.component.scss']
})
export class CustomLoginFormComponent extends LoginFormComponent {

  showExtendedLoginInfo: boolean;

  constructor(
    protected winRef: WindowRef,
    protected loginFormComponentService: CsutomLoginFormComponentService,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    protected customPopupShippingService?: CustomPopupShippingService,
    protected customRoutingConfigService?: CustomRoutingConfigService,
  ) {
    super(loginFormComponentService);
    this.showExtendedLoginInfo = this.customExtraAppConfigService.showExtendedLoginInfo();
    // Remove currentbasestore if is a private site.
    if (this.winRef?.localStorage?.getItem('currentbasestore') && this.customRoutingConfigService.isRoutingProtected()) {
      this.customPopupShippingService.purgeBaseStores();
    }
  }
}
