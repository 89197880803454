import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { ProductAttributesComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { Product, ProductScope } from '@spartacus/core';
import { CustomCurrentProductService } from "../../../../core/product/services/custom-current-product.service";

@Component({
  selector: 'app-custom-product-attributes',
  templateUrl: './custom-product-attributes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomProductAttributesComponent extends ProductAttributesComponent {
  product$: Observable<Product> = this.currentProductService.getProduct(
    [ProductScope.ATTRIBUTES, ProductScope.SPECIFICATIONS]
  );

  constructor(private occEndpointsService: OccEndpointsService,
              protected currentProductService: CustomCurrentProductService) {
    super(currentProductService);
  }

  getDownloadUrl(url: string): string {
    return this.occEndpointsService.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    }) + url;
  }
}
