<h2>{{ 'myFriends.label' | cxTranslate }}</h2>
<ng-container *ngIf="!isActive; else form">
  <ng-container *ngIf="!email; else emailComposer">
    <app-custom-my-friends-list
        (friend)="setFriend($event)"
        (edit)="setEdit($event)"
        (active)="setActive($event)"
        (userLogged)="setUser($event)"
        (email)="setEmail($event)"
    ></app-custom-my-friends-list>
  </ng-container>

  <ng-template #emailComposer>
    <app-custom-friend-email-form
      (email)="setEmail($event)"
    ></app-custom-friend-email-form>
  </ng-template>
</ng-container>

<ng-template #form>
    <app-custom-my-account-edit-form
        [friend]="friend"
        [edit]="edit"
        (active)="setActive($event)"
        [addForm]="false"
        [userLogged]="user"
    ></app-custom-my-account-edit-form>
</ng-template>
