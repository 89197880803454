import { Component, HostBinding, OnInit } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsLinkComponent, CmsService } from '@spartacus/core';
import { CustomCmsNewsSliderComponentModel } from './news-slider.model';
import { CmsBoxItemCategoriesComponent } from '../../../box-item-categories/box-item-categories.model';
import { CustomNewsSliderService } from './custom-news-slider.service';
import { CustomNewsModel } from '../../store/custom-news.model';

@Component({
  selector: 'app-custom-news-slider',
  templateUrl: './custom-news-slider.component.html',
  styleUrls: ['./custom-news-slider.component.scss'],
})
export class CustomNewsSliderComponent implements OnInit {

  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  buttons: any[] = [];
  news$: Observable<Observable<CustomNewsModel>[]>;

  data$ = this.componentData.data$.pipe(
    tap(component => {
      if (component?.category) {
        this.news$ = this.customNewsService.loadFilteredNews(
            component?.pageSize ?? 5,
            0,
            undefined,
            {
              categoryCode: component.category,
            }
          ).pipe(
            map(filteredNews => filteredNews.newsList.map(news => of(news))),
          );
      } else {
        this.customNewsService.fetchNewsSlider(
          component?.pageSize ?? 5
        );
        this.news$ = this.customNewsService.getNewsSlides().pipe(
          map(newsList => newsList.map(news => of(news))),
        );
      }
      return this.initComponent(component);
      },
    ),
  );

  buttons$: Observable<Array<CmsLinkComponent>> =
    this.data$.pipe(
      switchMap((data: any) =>
        combineLatest(
          (data?.buttons ?? '').trim().split(' ')?.map((codes: any) =>
            this.cmsService.getComponentData(codes)
          ),
        ),
      ),
    );

  constructor(
    private componentData: CmsComponentData<CustomCmsNewsSliderComponentModel>,
    private cmsService: CmsService,
    protected customNewsService: CustomNewsSliderService,
  ) {
  }

  getTarget(data: CmsBoxItemCategoriesComponent): string | null {
    return data.external === 'true' || data.external === true ? '_blank' : null;
  }

  ngOnInit(): void {

  }

  initComponent(item: CustomCmsNewsSliderComponentModel): void {
    this.styleClasses = item?.styleClasses ?? '';
    this.backgroundColor = item?.backgroundColorCode ?? '';
    if (item?.invertColumn === true || item?.invertColumn === 'true') {
      this.styleClasses += ' invert';
    }
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
  }

}
