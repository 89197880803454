import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { CmsComponentData, ICON_TYPE, LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { CustomMyFriendsDialogComponent } from '../custom-my-friends-dialog/custom-my-friends-dialog.component';
import { Friend, MyFriendsList } from '../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { CustomFriendsService } from '../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/custom-friends.service';
import { InvitationStatus } from '../../enum/custom-friends.enum';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { CmsComponent, TranslationService } from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { BusinessFriends } from '../../enum/business-friends.enum';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StateWithFriendsEmails } from '../custom-friend-email-form/store/custom-friends-email.state';
import { select, Store } from '@ngrx/store';
import { SelectFriendEmails } from '../custom-friend-email-form/store/actions/custom-friends-email.action';
import {
  getAllSelectedFriendEmailsLoading,
  getAllSelectedFriendEmailsSuccess,
  getAllSelectedFriendEmailsValue,
} from '../custom-friend-email-form/store/selector/custom-friends-email.selector';
import { CustomerEmailModel } from '../custom-friend-email-form/CustomerEmail.model';
import { CustomMyFriendListService } from './custom-my-friend-list.service';

export const FRIENDS_PAGE_SIZE = 5;

export interface CmsCustomMyFriendsListComponent extends CmsComponent {
  showEmailFeature: string;
}


@Component({
  selector: 'app-custom-my-friends-list',
  templateUrl: './custom-my-friends-list.component.html',
  styleUrls: ['./custom-my-friends-list.component.scss'],
})
export class CustomMyFriendsListComponent implements OnInit, OnDestroy {
  @Output() readonly active: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly friend: EventEmitter<Friend> = new EventEmitter<Friend>();
  @Output() readonly edit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly email: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() userLogged: EventEmitter<User> = new EventEmitter<User>();
  @Input() add: boolean;
  iconTypes = ICON_TYPE;
  currentPage = 0;
  pageSize = 20;
  displayFilter = false;
  friendList$: Observable<MyFriendsList>;
  loadingFriends = false;
  totalResults: number;
  sortFilterInviteStatus = [
    { code: '', selected: true },
    { code: 'INVITED', selected: false },
    { code: 'REGISTERED', selected: false },
    { code: 'NOT_INVITED', selected: false },
    { code: 'EXPIRED', selected: false },
    { code: 'UNKNOWN_EMAIL', selected: false },
  ];
  protected subscription = new Subscription();
  formArray: FormArray;
  validatedStore = false;
  user: User;
  unknownEmail = InvitationStatus.UNKNOWN_EMAIL;
  filtersForm: FormGroup = new FormGroup({
    textSearch: new FormControl(''),
    inviteStatus: new FormControl(''),
  });

  selectFriendsEmailForm: FormGroup;
  friendsList: Friend[];
  selectedFriendsEmails: CustomerEmailModel[];
  showEmailFeature: boolean;

  @HostBinding('class') styleClasses: string;
  data$: Observable<CmsCustomMyFriendsListComponent> = this.component.data$.pipe(
    tap((data) => {
      this.styleClasses = data.styleClasses;
      this.showEmailFeature = data.showEmailFeature === 'true';
    })
  );
  constructor(
    protected launchDialogService: LaunchDialogService,
    protected customFriendService: CustomFriendsService,
    protected userAccount: UserAccountFacade,
    protected friendsService: CustomFriendsService,
    protected translation: TranslationService,
    protected fb: FormBuilder,
    protected store: Store<StateWithFriendsEmails>,
    protected customMyFriendListService: CustomMyFriendListService,
    private cd: ChangeDetectorRef,
    protected component: CmsComponentData<CmsCustomMyFriendsListComponent>,
    protected vcr: ViewContainerRef,

  ) {
    this.selectFriendsEmailForm = this.fb.group({
      friendsList: new FormArray([]),
      selectAll: [false],
    });

    this.formArray = this.selectFriendsEmailForm.get('friendsList') as FormArray;
  }

  ngOnInit(): void {
    this.customFriendService.loadAllFriends(this.pageSize, this.currentPage);
    this.friendList$ = this.customFriendService.getAllFriends()
      .pipe(
        tap(
          (friends) => {
            if (friends?.members?.length > 1) {
              this.displayFilter = true;
            }
            this.totalResults = friends.pagination.totalResults;
          },
        ),
      );

    this.subscription.add(
      combineLatest([
        this.store.pipe(select(getAllSelectedFriendEmailsLoading)),
        this.store.pipe(select(getAllSelectedFriendEmailsSuccess)),
        this.store.pipe(select(getAllSelectedFriendEmailsValue)),
      ]).pipe(
        distinctUntilChanged((prev, next) => {
          return this.validatedStore = prev[2] === next[2];
        }),
      ).subscribe(([loading, success, value]) => {
        this.loadingFriends = loading;
        for (const friend of value) {
          this.verifyFormControl(friend);
        }
        if (!this.validatedStore) {
          this.addEmailToList();
        }
        this.selectedFriendsEmails = value;
        this.cd.markForCheck();
      }),
    );

    this.subscription.add(
      this.userAccount
        .get()
        .pipe(
          filter((user) => !!user),
        )
        .subscribe((user: User) => {
            this.user = user;
            this.userLogged.emit(user);
          },
        ),
    );
    this.subscription.add(
      this.filtersForm.controls.textSearch.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
        )
        .subscribe(() => this.pageChange(0)),
    );
  }

  verifyFormControl(friend: CustomerEmailModel): void {
    const friendFound = this.verifyCheck(friend.email);
    if (!friendFound) {
      this.addFormControl(friend);
    }
  }

  addFormControl(friend: CustomerEmailModel): void {
    this.formArray.push(new FormControl(friend));

  }

  onCheckChange(event, friend: Friend): void {
    if (event.target.checked) {
      this.verifyFormControl(this.customMyFriendListService.convertFriendToEmailData(friend));
    } else {
      let i = 0;
      this.formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value.email === friend.uid) {
          this.formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.addEmailToList();
  }

  verifyArrayCheck(friendsArray: Array<CustomerEmailModel>): void {
    for (const friend of friendsArray) {
      if (this.verifyCheck(friend.email)) {
        this.verifyFormControl(friend);
      }
    }
  }

  verifyCheck(email: string): boolean {
    const found = this.formArray.value.filter(friendEmail => {
      return friendEmail.email === email;
    });
    return !!found?.length;
  }

  selectAll(event): void {
    if (event.target.checked) {
      this.customFriendService.selectAllFriends(this.totalResults, null, this.filtersForm.value);
    } else {
      this.clearFormArray();
      this.customFriendService.deselectAllFriends();
    }
  }

  clearFormArray(): void {
    while (this.formArray.length !== 0) {
      this.formArray.removeAt(0);
    }
  }

  onChangeEvent(): void {
    this.addEmailToList();
  }

  addEmailToList(): void {
    const selectedFriends: Array<CustomerEmailModel> = this.selectFriendsEmailForm.controls.friendsList.value;
    this.store.dispatch(new SelectFriendEmails(selectedFriends));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  activeAddFriend(): void {
    this.edit.emit(false);
  }

  addFriend(value): void {
    this.active.emit(value);
  }

  sendInvitation(friend: Friend): void {
    this.friendsService.invitedFriend(friend);
  }


  openDialog(friend: Friend): void {
    sessionStorage.setItem('friendsCurrentpage', `${this.currentPage}`)
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.FRIENDLY,
      undefined,
      this.vcr,
      {
        friend
      }
      );

      if (dialog) {
        this.subscription.add(dialog.subscribe());
      }

  }
  invitationStatus(status: string): string {
    switch (status) {
      case InvitationStatus.GUEST: {
        return 'myFriends.guest';
      }
      case InvitationStatus.REGISTERED: {
        return 'myFriends.registered';
      }
      case InvitationStatus.NOT_INVITED: {
        return 'myFriends.notInvited';
      }
      case InvitationStatus.INVITED: {
        return 'myFriends.invited';
      }
      case InvitationStatus.EXPIRED: {
        return 'myFriends.expired';
      }
      case InvitationStatus.UNKNOWN_EMAIL: {
        return 'myFriends.unknownEmail';
      }
      default:
        return '';
    }
  }

  showIBAN(): boolean {
    return this.user.exclusiveRole !== BusinessFriends.SHAREHOLDER;
  }

  activeEditAction(friend: Friend): boolean {
    return friend.inviteStatus !== InvitationStatus.REGISTERED && this.user?.exclusiveRole !== BusinessFriends.SHAREHOLDER;
  }

  activeSendAction(friend: Friend): boolean {
    return friend.inviteStatus !== InvitationStatus.REGISTERED && friend.inviteStatus !== InvitationStatus.UNKNOWN_EMAIL;
  }

  editFriend(friend: Friend): void {
    this.friend.emit(friend);
    this.edit.emit(true);
    this.active.emit(true);
  }

  getFilterInviteStatusLabels(): Observable<{
    GUEST: string;
    INVITED: string;
    REGISTERED: string;
    NOT_INVITED: string;
    EXPIRED: string;
    UNKNOWN_EMAIL: string;
    '': string;
  }> {
    return combineLatest([
      this.translation.translate('myFriends.guest'),
      this.translation.translate('myFriends.invited'),
      this.translation.translate('myFriends.registered'),
      this.translation.translate('myFriends.notInvited'),
      this.translation.translate('myFriends.expired'),
      this.translation.translate('myFriends.unknownEmail'),
      this.translation.translate('myFriends.allStatus'),
    ]).pipe(
      map(([
        textGuest,
        textInvited,
        textRegistered,
        textNotInvited,
        textExpired,
        textUnknownEmail,
        textAllStatus,
      ]) => {
        return {
          GUEST: textGuest,
          INVITED: textInvited,
          REGISTERED: textRegistered,
          NOT_INVITED: textNotInvited,
          EXPIRED: textExpired,
          UNKNOWN_EMAIL: textUnknownEmail,
          '': textAllStatus,
        };
      }),
    );
  }

  pageChange(currentPage: number, pageSize?: number): void {
    const event: { currentPage: number, pageSize: number } = {
      currentPage,
      pageSize
    };
    this.currentPage =  currentPage;
    this.pageSize = pageSize;

    this.fetchFriends(event);
  }

  removeFilter(filterKeys: string[], reload: boolean = true): void {
    filterKeys.forEach(filterKey => {
      this.filtersForm.get(filterKey)?.reset();
    });
    if (reload) {
      this.pageChange(0);
    }
  }

  updateSelectFilter(filterKey: string, selectedCode: string): void {
    this.filtersForm.get(filterKey)?.patchValue(selectedCode);
    this.pageChange(0);
  }

  private fetchFriends(event: { currentPage: any, pageSize: number }): void {
    this.customFriendService.loadAllFriends(
      event.pageSize,
      event.currentPage,
      this.filtersForm.value,
    );

    const formArray: FormArray = this.selectFriendsEmailForm.get('friendsList') as FormArray;
    this.verifyArrayCheck(formArray.value);
  }

  openEmailDialog(): void {

  }

  showEmailComposer(showComposer): void {
    this.email.emit(showComposer);
    this.edit.emit(false);
    this.active.emit(false);
  }
}
