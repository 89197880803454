import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCategorySummaryComponent } from './custom-category-summary.component';
import { CmsConfig, PageMetaModule, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [CustomCategorySummaryComponent],
  imports: [
    CommonModule,
    PageMetaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomCategorySummaryComponent: {
            component: CustomCategorySummaryComponent,
        }
      }
    } as CmsConfig)
  ],
})
export class CustomCategorySummaryModule { }
