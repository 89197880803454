<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.ITEM">
  <div [ngClass]="compact ? 'cx-compact' : ''">

    <!-- Item Information -->
    <div class="cx-info">
      <!-- Item Image -->
      <div class="cx-image-container">
        <a
          [routerLink]="{ cxRoute: 'product', params: routerLinkParams } | cxUrl"
          [queryParams]="navigationExtras.queryParams"
          [state]="navigationExtras.state"
          cxModal="dismiss"
          tabindex="-1"
        >
          <cx-media
            [container]="item.product.images?.PRIMARY ? item.product.images?.PRIMARY : item.product.images"
            format="thumbnail"
          ></cx-media>
        </a>
      </div>
      <div class="cx-info-container">
        <div *ngIf="item.product.name" class="cx-name">
          <a
            class="cx-link"
            [routerLink]="{ cxRoute: 'product', params: routerLinkParams } | cxUrl"
            [queryParams]="navigationExtras.queryParams"
            [state]="navigationExtras.state"
            cxModal="dismiss"
            >{{ item.product.name }}</a
          >
        </div>
        <a
          *ngIf="item.packProduct"
          [routerLink]="{ cxRoute: 'product', params: item.packProduct } | cxUrl"
        >
          <span class="badge badge-background">
            {{ item.packProduct.name }}
          </span>
        </a>
        <!-- Availability -->
        <div
          *ngIf="isProductOutOfStock(item.product)"
          class="cx-availability"
        >
          {{ "addToCart.outOfStock" | cxTranslate }}
        </div>
        <!-- Promotion -->
        <div *ngIf="displayPromotion == 'cart' && !displayUnitSlim">
          <ng-container *ngIf="appliedProductPromotions$ | async as appliedProductPromotions">
            <app-custom-promotions class="cx-promotions-applied" [promotions]="appliedProductPromotions"></app-custom-promotions>
          </ng-container>
        </div>
      </div>
      <!-- Item Details Outlet -->
      <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"> </ng-template>
      <!-- Variants -->
      <ng-container *ngIf="item.product.baseOptions?.length">
        <div
          *ngFor="
            let variant of item.product.baseOptions[0]?.selected
              ?.variantOptionQualifiers
          "
          class="cx-property"
        >
          <div class="cx-label" *ngIf="variant.name && variant.value">
            {{ variant.name }}: {{ variant.value }}
          </div>
        </div>
      </ng-container>
      <ng-container [ngSwitch]="displayUnitSlim">
        <ng-container *ngSwitchCase="true">
          <div class="cx-info-price-slim">
            <span class="cx-info-price-slim-price">
              {{ item.basePrice?.formattedValue }} {{ 'addToCart.eachUnit' | cxTranslate }}
            </span>
            <span class="cx-info-price-slim-info">
              {{ 'addToCart.quantityUnit' | cxTranslate: { 
                context: (unitPrice?.unit?.name || item?.unit?.name ? '' : 'less'), 
                qty: quantityControl.value, 
                name: unitPrice?.unit?.name ? unitPrice.unit.name : item?.unit?.name,
                measurementUnit: item.product.measurementUnit ? (' ' + item.product.measurementUnit) : '' 
              } }}
            </span>
            <!-- Promotion -->
            <div *ngIf="displayPromotion == 'cart'">
              <ng-container *ngIf="appliedProductPromotions$ | async as appliedProductPromotions">
                <app-custom-promotions class="cx-promotions-applied" [promotions]="appliedProductPromotions"></app-custom-promotions>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="cx-info-price">
            <!-- Item Price -->
            <!--<div *ngIf="item.basePrice" class="cx-price">
                <div class="cx-value">
                  {{ item.basePrice?.formattedValue }}
                </div>
              </div> -->
            <app-custom-unit-display 
            [unitPrice]="unitPrice" 
            [unit]="item.unit" 
            [measurementUnit]="item.product.measurementUnit"
            [price]="item.basePrice"></app-custom-unit-display>
            <!-- Item Quantity -->
            <div class="cx-quantity">
              <div class="cx-value">
                <cx-item-counter
                  [control]="quantityControl"
                  [readonly]="
                    !item.updateable || readonly || options.isSaveForLater
                  "
                  [max]="getMaxQty()"
                  [allowZero]="true"
                >
                </cx-item-counter>
              </div>
            </div>
          </div>
          <div class="cx-delivery-information" *ngIf="warehousesCount > 1 && item.warehouseIndex">
            {{'cartItems.deliveryNumber' | cxTranslate: {count: item.warehouseIndex + '/' + warehousesCount} }}
          </div>
        </ng-container>
      </ng-container>
      <!-- Total -->
      <ng-container *ngIf="options.isSaveForLater; else total">
        <div class="cx-total">
          <div
            class="cx-label"
            [ngClass]="
              compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'
            "
          >
            {{ "saveForLaterItems.stock" | cxTranslate }}
          </div>
          <div
            *ngIf="item.product?.stock?.stockLevel >= 0; else forceInstock"
            class="cx-value"
          >
            {{ item.product.stock.stockLevel }}
          </div>
          <ng-template #forceInstock>
            <div class="cx-value">
              {{ "saveForLaterItems.forceInStock" | cxTranslate }}
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #total>
        <div *ngIf="item.totalPrice" class="cx-total">
          <div class="cx-value">{{ item.totalPrice.formattedValue }}</div>
          <!-- Promotion -->
          <div *ngIf="displayPromotion == 'modal'">
            <ng-container *ngIf="appliedProductPromotions$ | async as appliedProductPromotions">
              <app-custom-promotions class="cx-promotions-applied" [promotions]="appliedProductPromotions"></app-custom-promotions>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <!-- Remove -->
      <ng-container *ngIf="remove">
        <div class="cx-remove-btn">
          <button
            class="link cx-action-link"
            [disabled]="quantityControl.disabled"
            (click)="removeItem()"
          >
            <i class="icon-trash"></i>
          </button>
        </div>
      </ng-container>

      <!-- Item Bundle Details Outlet -->
      <ng-template [cxOutlet]="CartOutlets.ITEM_BUNDLE_DETAILS"> </ng-template>

      <!-- Actions -->
      <div
        *ngIf="(!readonly || options.isSaveForLater) && item.updateable"
        class="cx-actions"
      >
        <ng-container *ngIf="!isProductOutOfStock(item.product)">
          <ng-container
            *ngTemplateOutlet="
              options.optionalBtn;
              context: {
                $implicit: { loading: quantityControl.disabled, item: item }
              }
            "
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
