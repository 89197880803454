import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomNewsListService } from './custom-news-list.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { CustomNewsListModel } from '../../store/custom-news.model';
import { TranslationService } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-news-list',
  templateUrl: './custom-news-list.component.html',
})
export class CustomNewsListComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  currentPage = 0;
  sortType: string;
  newsFormFilter: FormGroup;
  PAGE_SIZE_OPTIONS = [
    { code: 50, name: '50' },
    { code: 100, name: '100' },
    { code: 200, name: '200' },
  ];
  CATEGORY_OPTIONS: any[] = [];

  iconTypes = ICON_TYPE;
  news$: Observable<CustomNewsListModel>;

  isLoaded$: Observable<boolean> = this.customNewsService.getNewsListLoading();
  success: Observable<boolean> = this.customNewsService.getNewsListSuccess();
  error: Observable<boolean> = this.customNewsService.getNewsListError();

  constructor(
    protected customNewsService: CustomNewsListService,
    protected fb: FormBuilder,
    protected translation: TranslationService,
  ) {
    this.newsFormFilter = this.fb.group({
      titleSearch: [null],
      pageSize: [50],
      categoryCode: [''],
    });
  }

  ngOnInit(): void {
    this.news$ = this.customNewsService
    .getNewsList(this.newsFormFilter.get('pageSize').value)
    .pipe(
      tap((news: CustomNewsListModel) => {
        if (news.pagination) {
          this.sortType = news.pagination.sort;
        }
      }),
    );

    this.subscription.add(
      this.newsFormFilter.controls.titleSearch.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(() => this.pageChange(0)),
    );
    this.getCategories();
    this.customNewsService.loadNewsCategories();
  }

  changeSortCode(sortCode: string): void {
    const event: { sortCode: string; currentPage: number } = {
      sortCode,
      currentPage: 0,
    };
    this.sortType = sortCode;
    this.fetchNews(event);
  }


  pageChange(page: number): void {
    const event: { sortCode: string; currentPage: number } = {
      sortCode: this.sortType,
      currentPage: page,
    };
    this.fetchNews(event);
  }

  fetchNews(event: { sortCode: string; currentPage: number }): void {
    this.customNewsService.loadNewsList(
      this.newsFormFilter.get('pageSize').value,
      event.currentPage,
      event.sortCode,
      this.newsFormFilter.value,
    );
  }

  removeFilter(filterKeys: string[], reload: boolean = true): void {
    filterKeys.forEach(filterKey => {
      this.newsFormFilter.get(filterKey)?.reset();
    });
    if (reload) {
      this.pageChange(0);
    }
  }

  updateFilterData(filterKey: string, selectedCode: string): void {
    if (selectedCode) {
      this.newsFormFilter.get(filterKey)?.patchValue(selectedCode);
    }
    this.pageChange(0);
  }

  getCategories(): void {
    combineLatest([
      this.customNewsService.getNewsCategories(),
      this.customNewsService.getNewsCategoriesSuccess(),
      this.customNewsService.getNewsCategoriesError(),
      this.customNewsService.getNewsCategoriesLoading(),
      this.translation.translate(`news.filters.category.ALL`),
      this.translation.translate(`news.filters.category.loading`),
    ]).subscribe(([newsCategories, success, error, loading, allTranslation, loadingTranslation]) => {
      const categories = newsCategories?.categories;
      if (loading) {
        this.CATEGORY_OPTIONS = [
          {
            code: '',
            name: loadingTranslation,
          },
        ];
      }

      if (success || error) {
        this.CATEGORY_OPTIONS = [
          {
            code: '',
            name: allTranslation,
          },
        ];
      }

      if (success && categories) {
        this.CATEGORY_OPTIONS = [
          ...this.CATEGORY_OPTIONS,
          ...categories,
        ];
      }
    });
  }

  getSortLabels(): Observable<{ byDate: string; byOrderNumber: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
    ]).pipe(
      map(([textByDate, textByOrderNumber]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
        };
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
