import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutConfig } from '@spartacus/storefront';
import { ConfigModule } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: {
          min: 1200,
        },
      },
      layoutSlots: {
        header: {
          lg: {
            slots: [
              'HeaderText',
              'SiteLinks',
              'SiteContext',
              'SiteLogo',
              'NavigationBar',
              'SearchBox',
              'SiteLogin',
              'MiniCart',
            ],
          },
          slots: [
            'HeaderText',
            'SiteLogo',
            'SearchBox',
            'MiniCart',
            'PreHeader',
          ],
        },
        navigation: {
          xs: {
            slots: ['SiteLogin', 'NavigationBar', 'SiteLinks', 'SiteContext'],
          },
        },
        footer: {
          slots: [
            'Footer',
            'FooterLeftSide',
            'FooterRightSide',
            'FooterBottom',
          ],
        },
        LandingPage2Template: {
          pageFold: 'BottomHeaderSlot',
          slots: ['BottomHeaderSlot', 'Section1', 'Section3'],
        },
        FooterLessPageTemplate: {
          header: {
            slots: ['SiteLogo'],
            xs: {
              slots: ['SiteLogo', 'PreHeader'],
            },
          },
          navigation: {
            slots: ['SiteLinks', 'SiteContext'],
          },
          footer: {
            slots: [],
          },
          slots: ['RightContentSlot', 'LeftContentSlot'],
        },
        ContentPagePublicTemplate: {
          header: {
            slots: [
              'HeaderText',
              'SiteLogo'
            ],
            xs: {
              slots: [
                'HeaderText',
                'SiteLogo',
                'PreHeader'
              ],
            },
          },
          navigation: {
            slots: ['SiteLinks', 'SiteContext'],
          },
          footer: {
            slots: [
              'FooterBottom',
            ],
          },
          slots: ['Section2A', 'Section2B'],
        },
        LoginPageTemplate: {
          header: {
            slots: [
              'HeaderText',
              'SiteLogo'
            ],
            xs: {
              slots: [
                'HeaderText',
                'SiteLogo',
                'PreHeader'
              ],
            },
          },
          navigation: {
            slots: ['SiteLinks', 'SiteContext'],
          },
          footer: {
            slots: [
              'FooterBottom',
            ],
          },
          slots: ['RightContentSlot', 'LeftContentSlot'],
        },
        CategoryPageTemplate: {
          pageFold: 'Section2',
          slots: ['Section1', 'Section2', 'Section3'],
        },
        MultiStepCheckoutSummaryPageTemplate: {
          footer: {
            slots: [],
          },
          lg: {
            slots: ['TopContent', 'BodyContent', 'SideContent'],
          },
        },
        AccountPageTemplate: {
          lg: {
            slots: ['BodyContent', 'SideContent'],
          },
         slots: ['BodyContent'],
        },
        ProductGridPageTemplate: {
          pageFold: 'Section2',
          lg: {
            slots: [
              'Section1',
              'Section2',
              'ProductGridSlot',
              'ProductLeftRefinements',
              'Section4',
            ],
          },
          xs: {
            slots: [
              'Section1',
              'Section2',
              'ProductLeftRefinements',
              'ProductGridSlot',
              'Section4',
            ],
          },
        },
        SearchResultsListPageTemplate: {
          pageFold: 'Section2',
          lg: {
            slots: [
              'Section2',
              'SearchResultsListSlot',
              'ProductLeftRefinements',
              'Section4',
            ],
          },
          xs: {
            slots: [
              'Section2',
              'ProductLeftRefinements',
              'SearchResultsListSlot',
              'Section4',
            ],
          },
        },
        NewsPageTemplate: {
          slots: ['NewsTitle', 'NewsContent'],
        },
        LandingPageTransparentHeaderTemplate: {
          slots: ['SliderHeader', 'Section1', 'Section2A', 'Section2B', 'Section3'],
        },
        ContentPage1Template: {
          pageFold: 'BottomHeaderSlot',
          slots: ['Section1', 'Section2A', 'Section2B', 'Section3'],
        },
        PreferenceCenterPageTemplate: {
          slots: ['TopContent','MiddleContent','BodyContent'],
        },
      },
    } as LayoutConfig),
  ],
})
export class CustomLayoutModule {}
