import { Observable } from 'rxjs';
import {
  CustomNewsCategoriesModel,
  CustomNewsDetailsModel,
  CustomNewsListModel,
  NewsFilter,
} from '../store/custom-news.model';
import { CustomNewsAdapter } from './custom-news.adapter';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomNewsConnector {
  constructor(protected adapter: CustomNewsAdapter) {
  }

  public getNewsList(
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    newsFilter?: NewsFilter,
  ): Observable<CustomNewsListModel> {
    return this.adapter.loadNewsList(pageSize, currentPage, sort, newsFilter);
  }

  public getNewsDetails(
    newsId: string,
  ): Observable<CustomNewsDetailsModel> {
    return this.adapter.loadNewsDetails(newsId);
  }

  public getNewsCategories(): Observable<CustomNewsCategoriesModel> {
    return this.adapter.loadNewsCategories();
  }
}
