<ng-container *ngIf="news$ | async as news">
  <!-- HEADER -->
  <div class="custom-news-filter">
    <form [formGroup]="newsFormFilter">
      <div class="row">
        <div class="col-12 text-md-right">
          <div class="form-group ml-0">
            <label>
                <span class="label-content">
                {{ "news.categories" | cxTranslate }}
                  <ng-select
                    class="category-filter"
                    formControlName="categoryCode"
                    [searchable]="false"
                    [clearable]="false"
                    [items]="CATEGORY_OPTIONS"
                    bindLabel="name"
                    bindValue="code"
                    (change)="updateFilterData('categories', $event?.code)"
                  ></ng-select>
                </span>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content">
                {{ "news.filterNewsTitle" | cxTranslate }}
                <span class="searchbox custom-news-list-input">
                  <cx-icon
                    [type]="iconTypes.SEARCH"
                    aria-label="search"
                    class="search prepend"
                    tabindex="0"
                  ></cx-icon>
                  <input
                    type="text"
                    formControlName="titleSearch"
                    class="form-control"
                    [placeholder]="'searchBox.placeholder' | cxTranslate"
                    aria-label="search"
                  >
                    <cx-icon
                      class="search append"
                      [cxIcon]="iconTypes.RESET" (click)="removeFilter(['titleSearch'], true)"
                      [hidden]="!newsFormFilter.get('titleSearch').value"
                    ></cx-icon>
                </span>
              </span>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content">
              {{ "news.numberToShow" | cxTranslate }}
                <ng-select
                  formControlName="pageSize"
                  [searchable]="false"
                  [clearable]="false"
                  [items]="PAGE_SIZE_OPTIONS"
                  bindLabel="name"
                  bindValue="code"
                  (change)="updateFilterData('pageSize', $event?.code)"
                ></ng-select>
              </span>
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>

  <hr class="mt-5 mb-5"/>

  <ng-container *ngIf="(isLoaded$ | async); else results">
    <cx-spinner></cx-spinner>
  </ng-container>

  <ng-template #results>
    <ng-container *ngIf="news.pagination.totalResults > 0; else noNews">
      <!-- TABLE -->
      <div class="row">
        <ng-container *ngFor="let newsItem of news.newsList">
          <div class="item-media col-lg-4 col-md-6 col-xs-12">
            <cx-media
              class="item-media"
              [container]="newsItem.coverImage"
              format="product"
              [alt]="newsItem.newsTitle"
            ></cx-media>
            <div class="news-date">{{newsItem.publishDate | cxDate: 'mediumDate' }}</div>
            <h2 class="news-title">
              <a [routerLink]="newsItem.label">
                {{newsItem.newsTitle}}
              </a>
            </h2>
            <p class="news-thumbnail-description">{{newsItem.thumbnailDescription}}</p>
            <a [routerLink]="newsItem.label" class="btn btn-link btn-read-more-alt">{{"news.readMore" | cxTranslate}}</a>
          </div>

        </ng-container>
      </div>
      <!-- Select Form and Pagination Bottom -->
      <div class="table-main-bottom row">
        <div *ngIf="news.pagination.totalPages > 1" class="table-main-pagination">
          <cx-pagination
            [pagination]="news.pagination"
            (viewPageEvent)="pageChange($event)"
          ></cx-pagination>
        </div>
      </div>
    </ng-container>


    <!-- NO NEWS CONTAINER -->
    <ng-template #noNews>
      <div class="custom-list-filter-no-order">
        {{ (newsFormFilter.get('titleSearch').value?.length ? "news.notFound" : "news.filtersNotFound") | cxTranslate }}
      </div>
    </ng-template>
  </ng-template>
</ng-container>
