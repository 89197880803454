import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLoginFormComponent } from './custom-login-form.component';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [CustomLoginFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule
  ],
  providers: [
    provideConfig(<CmsConfig> {
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: CustomLoginFormComponent,
       }
      }
    })
  ]
})
export class CustomLoginFormModule { }
