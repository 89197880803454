import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CmsPDFDocumentComponent, CmsService, OccEndpointsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsCustomFilesInfoModel } from './custom-files-info.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-files-info-component',
  templateUrl: './custom-files-info.component.html',
})
export class CustomFilesInfoComponent implements OnInit {

  @HostBinding('class') styleClasses: string;
  @Input() files: Observable<CmsCustomFilesInfoModel>;

  data$: Observable<CmsCustomFilesInfoModel>;
  downloadUrl: string;

  constructor(
    protected componentData: CmsComponentData<CmsCustomFilesInfoModel>,
    protected cmsService: CmsService,
    protected occEndpointsService: OccEndpointsService,
  ) {
  }

  ngOnInit(): void {
    if (this.files) {
      this.data$ = this.files.pipe(
        tap(component => {
          this.styleClasses = component.styleClasses;
          this.downloadUrl = component.pdfFile ? this.getDownloadUrl(component.pdfFile.downloadUrl) : this.getDownloadUrl(component.file.downloadUrl);
        }),
      );
    } else {
      this.data$ = this.componentData.data$.pipe(
        tap(component => {
          this.styleClasses = component.styleClasses;
        }),
      );
    }
  }

  getDownloadUrl(url: string): string {
    return this.occEndpointsService.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    }) + url;
  }
}

