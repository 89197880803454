import { CanActivate, Router, UrlTree } from '@angular/router';
import { GlobalMessageService, GlobalMessageType, SemanticPathService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { BusinessFriends } from '../enum/business-friends.enum';
import { Injectable } from '@angular/core';
import { CustomExtraAppConfigService } from 'src/app/custom/config/services/custom-extra-app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CustomFriendsGuard implements CanActivate {
  constructor(
    private userAccount: UserAccountFacade,
    protected globalMessageService: GlobalMessageService,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    protected semanticPathService: SemanticPathService,
    protected router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    if (!this.customExtraAppConfigService.allowFriends()) {
      return of(this.router.parseUrl(this.semanticPathService.get('home')));
    }
    return this.userAccount.get().pipe(
      filter((user) => !!user),
      map((user: User) => {
        if (!this.hasPageAccess(user.exclusiveRole)) {
          this.globalMessageService.add(
            {
              key: 'myFriends.noSufficientPermissions',
            },
            GlobalMessageType.MSG_TYPE_WARNING
          );
          return this.router.parseUrl(this.semanticPathService.get('home'));
        }
        return true;
      })
    );
  }

  private hasPageAccess(role: string): boolean {
    return role === BusinessFriends.EMPLOYEE || role === BusinessFriends.SHAREHOLDER || role === BusinessFriends.EMPLOYEE_CREDIT_CARD;
  }
}
