import { style } from '@angular/animations';
import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AuthService, WindowRef} from '@spartacus/core';
import { LoginComponent } from '@spartacus/user/account/components'
import { CustomValidateCartService } from 'src/app/services/cart/validate-cart.service';
import { CustomAgeStatusService } from '../../../../../custom/cms-components/header/age-status/custom-age-status.service';
import { CustomExtraAppConfigService } from '../../../../../custom/config/services/custom-extra-app-config.service';
import {CookieService} from '../../../../../custom/config/services/cookie.service';
import { LAUNCH_CALLER, LaunchDialogService, PageLayoutService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomPopupShippingService } from 'src/app/custom/cms-components/user/popup-shipping/custom-popup-shipping.service';
import { Subscription, of } from 'rxjs';

@Component({
  selector: 'app-custom-login',
  templateUrl: './custom-login.component.html',
  styleUrls: ['./custom-login.component.scss'],
})
export class CustomLoginComponent extends LoginComponent implements OnDestroy {
  modalRef;
  closeResult: string;
  showAgePopup = this.customExtraAppConfigService.showAgePopup();
  currentBaseStore = '';
  ageStatus = 0;

  @ViewChild('open') element: ElementRef;
  subscriptions: Subscription = new Subscription();
  @HostListener('window:scroll', [])
  onWindowScroll() {
    let stickyStart = this.winRef?.document.documentElement.scrollTop > 1;
    this.winRef?.document.getElementsByTagName("header")[0]?.classList.toggle('sticky', stickyStart);
    const isRioFrio = this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.contains('revolution');
    if (isRioFrio) {
      this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.toggle('sticky-banner', stickyStart);
    }
  }
  constructor(
    protected modalService: LaunchDialogService,
    auth: AuthService,
    protected cartService: CustomValidateCartService,
    protected customAgeStatusService: CustomAgeStatusService,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    userAccount: UserAccountFacade,
    protected vcr: ViewContainerRef,
    protected customPopupShippingService: CustomPopupShippingService,
    protected pageLayoutService: PageLayoutService,
    protected winRef?: WindowRef,
    protected cookieService?: CookieService
  ) {
    super(auth, userAccount);
  }

  ngOnInit(): void {
    super.ngOnInit();

    let actualBaseStore;
    let showModal = true;
    this.currentBaseStore = this.winRef?.localStorage?.getItem('currentbasestore');

    if (this.showAgePopup) {
      this.ageStatus =  +this.cookieService.get('agestatus');
      if (this.ageStatus >= 0) {
        this.customAgeStatusService.updateAgeStatus(this.ageStatus);
      }
    } else {
      this.ageStatus = 1;
    }

    if (this.currentBaseStore) {
      actualBaseStore = JSON.parse(this.currentBaseStore);
      if (actualBaseStore?.code?.trim().length) {
        if (this.ageStatus) {
          showModal = false;
        }
      } else {
        this.customPopupShippingService.purgeBaseStores();
      }
    }

    if (showModal && !!this.winRef?.localStorage) {
      this.openModal();
    }
    const hasHeaderBanner = this.winRef?.document.getElementsByClassName("HeaderText")[0]?.classList.contains('has-components');
    const isRioFrio = this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.contains('revolution');
    if (hasHeaderBanner) {
     this.winRef?.document.getElementsByTagName("header")[0]?.classList.add('has-header-banner');
     if (isRioFrio) {
      this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.add('has-header-banner');
     }
    } else {
      this.winRef?.document.getElementsByTagName("header")[0]?.classList.remove('has-header-banner');
      if (isRioFrio) {
        this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.remove('has-header-banner');
      }
    }
  }

  dismissModal(reason?: any): void {
    this.modalService.closeDialog(reason);
  }

  openModal(): void {
    //CustomPopupShippingComponent
    this.pageLayoutService.page$.subscribe((data)=> {
      if(data.template !== 'PreferenceCenterPageTemplate') {
        const dialog = this.modalService.openDialog(
          LAUNCH_CALLER.HEADER_SHIPPING,
          undefined,
          this.vcr,
          {
            showButtonCancel: false,
          }
        );
    
        if (dialog) {
          this.subscriptions.add(dialog.subscribe());
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
