import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { createFrom, EventService, StateEventService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomCleanEcommerceEvent } from "../../common/custom-common.events";
import { EcommerceType } from "../../tag-manager-feature.model";
import { getProductItems } from "../common/custom-checkout.utils";
import { CustomOrderPlacedEvent } from './custom-custom-checkout.events';
import { Cart } from '@spartacus/cart/base/root';
import { PLACE_ORDER } from '../checkout-steps/store/actions/custom-checkout.action';
import { OrderService } from '@spartacus/order/core';
import { Order } from '@spartacus/order/root/model';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutEventBuilder {
  constructor(
    protected actions: ActionsSubject,
    protected stateEventService: StateEventService,
    protected eventService: EventService
  ) {
    this.register();
  }

  /**
   * Registers checkout events
   */
  protected register(): void {
    this.orderPlacedEvent();
    this.eventService.register(CustomOrderPlacedEvent, this.buildPurchaseEvent());
  }

  /**
   * Builds the purchase events
   */
  protected buildPurchaseEvent(): Observable<CustomOrderPlacedEvent> {
    return this.actions.pipe(
      ofType(PLACE_ORDER),//CheckoutActions.PLACE_ORDER_SUCCESS),
      tap(() => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ ecommerce: null }, CustomCleanEcommerceEvent)),
      map((action: any) => {
        const order = action.payload;
        return createFrom(CustomOrderPlacedEvent, {
          event: EcommerceType.PURCHASE,
          ecommerce: {
            transaction_id: order.code,
            value: order.totalPrice.value.toString(),
            tax: order.totalTax.value.toString(),
            shipping: order.deliveryCost.value.toString(),
            currency: order.totalPrice.currencyIso,
            coupon: this.getOrderPromotionName(order) || '',
            items: getProductItems(order.entries, order.giftCardProduct),
          }
        });
      },
      ),
    );
  }

  private getOrderPromotionName(cart: Cart): string | undefined {
    if (cart && cart.appliedOrderPromotions && cart.appliedOrderPromotions.length) {
      return cart.appliedOrderPromotions[0].promotion.code
    }
    return undefined;
  }

  /**
   * Register an order successfully placed event
   */
  protected orderPlacedEvent(): void {
    this.stateEventService.register({
      action: PLACE_ORDER,
      event: CustomOrderPlacedEvent,
    });
  }


  public dispatchPurchaseModeCompleteEvent(orders?: any): Observable<any> {
    return this.actions.pipe(
      tap((action: any) => {
        var order = action.payload;
        if(order.totalPrice) {
          order = orders;
        }
        this.eventService.dispatch(<CustomCleanEcommerceEvent>{ ecommerce: null }, CustomCleanEcommerceEvent)
        this.eventService.dispatch(<CustomOrderPlacedEvent>{
          event: EcommerceType.PURCHASE,
          ecommerce: {
            transaction_id: order.code,
            value: order.totalPrice.value.toString(),
            tax: order.totalTax.value.toString(),
            shipping: order.deliveryCost.value.toString(),
            currency: order.totalPrice.currencyIso,
            coupon: this.getOrderPromotionName(order) || '',
            items: getProductItems(order.entries, order.giftCardProduct),
          }
        }, CustomOrderPlacedEvent)
      }),
    );
  }

}
