<h4>{{ edit ? ('myFriends.edit' | cxTranslate) : ('myFriends.add' | cxTranslate) }}</h4>
<div class="row">
  <div class="col-md-9">
    <span class="info">{{ 'myFriends.contactInfo' | cxTranslate }}</span>
  </div>
  <div class="col-md-3"></div>
</div>
<form [formGroup]="addFriendForm">
  <div class="form-group">
    <label>
      <span class="label-content required">{{ 'myFriends.myFriendsForm.name' | cxTranslate }}</span>
      <input maxlength="35" class="form-control" type="text" [formControl]="addFriendForm.get('firstNameLastName')" />
      <cx-form-errors [control]="addFriendForm.get('firstNameLastName')"></cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required">{{ 'myFriends.myFriendsForm.email' | cxTranslate }}</span>
      <ng-container *ngIf="addFriendForm.get('newUid')">
        <input class="form-control" [readOnly]="false" type="text" [formControl]="addFriendForm.get('newUid')"/>
      </ng-container>
      <input
        [maxLength]="60"
        class="form-control"
        [readOnly]="edit"
        [type]="hideUidControl ? 'hidden' : 'text'"
        [formControl]="addFriendForm.get('uid')"
      />
      <cx-form-errors [control]="hideUidControl ? addFriendForm.get('newUid') : addFriendForm.get('uid')"></cx-form-errors>
    </label>
  </div>
  <div class="form-group">
    <label>
      <span class="label-content">{{ 'myFriends.myFriendsForm.personalMessage' | cxTranslate }}</span>
      <textarea
        [maxLength]="240"
        class="form-control"
        placeholder="{{ 'myFriends.myFriendsForm.personalMessagePlaceholder' | cxTranslate }}"
        formControlName="emailComments"
        rows="4"
      ></textarea>
      <cx-form-errors [control]="addFriendForm.get('emailComments')"></cx-form-errors>
    </label>
  </div>

  <!-- BUTTON SECTION -->
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <button class="btn btn-block btn-default" (click)="goBack()">
        {{ 'myFriends.goBack' | cxTranslate }}
      </button>
    </div>

    <div class="col-md-12 col-lg-6">
      <button
        class="btn btn-block btn-action"
        type="submit"
        (click)="edit ? updateFriend() : saveFriend()"
        [disabled]="formEdit()"
      >
        {{ 'myFriends.saveData' | cxTranslate }}
      </button>
    </div>
  </div>
</form>
