import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  GlobalMessageService,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { CustomUpdateProfileComponentService } from './custom-update-profile-component.service';
import { CustomUpdateProfileComponent } from './custom-update-profile.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    RouterModule,
    NgSelectModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        UpdateProfileComponent: {
          component: CustomUpdateProfileComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: CustomUpdateProfileComponentService,
              useClass: CustomUpdateProfileComponentService,
              deps: [UserProfileFacade, GlobalMessageService],
            },
          ],
        }
      }
    } as CmsConfig)
  ],
  declarations: [CustomUpdateProfileComponent],
  exports: [CustomUpdateProfileComponent],
})
export class CustomUpdateProfileModule {}
